import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  generatePass,
} from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import {
  validateEmail,
  debouncedValidateEmail,
} from 'shared/validation';
import { addOrderManager, patchLicenseUser, checkExistingUser } from 'orders/OrderContainer/actions';
import ExistingUserForm from '../ExistingUserForm';

const OrderManagerForm = ({
  closeCb,
  confirmCb,
  orderID,
  orderManagers,
  manager,
}) => {
  const { t } = useTranslation();
  const managerToEditID = get(manager, 'id');

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  const [email, setEmail] = useState(get(manager, 'true_email') || '');
  const [emailError, setEmailError] = useState('');
  const [firstName, setFirstName] = useState(get(manager, 'first_name') || '');
  const [lastName, setLastName] = useState(get(manager, 'last_name') || '');
  const [phoneNumber, setPhoneNumber] = useState(get(manager, 'phone_number') || '');
  const [password, setPassword] = useState(generatePass());
  // existing user
  const [isExistingUserFormDisplayed, setExistingUserFormDisplay] = useState(null);

  const checkManagersList = (val) => {
    const managersEmailList = orderManagers.map(om => om.true_email.toLowerCase());
    const doesEmailExist = managersEmailList.includes(val.toLowerCase());
    return doesEmailExist;
  };

  const handleEmailChange = (val) => {
    const isManagerAlreadyAdded = checkManagersList(val);

    if (!manager && isManagerAlreadyAdded) {
      setDirty(true);
      setEmail(val);
      setEmailError('Manager with this email is already added to the list');
      return false;
    }

    setDirty(true);
    setEmail(val);
    debouncedValidateEmail(val).then(err => setEmailError(err));
    return true;
  };

  const handleEmailValidation = async () => {
    const isManagerAlreadyAdded = checkManagersList(email);
    if (!manager && isManagerAlreadyAdded) {
      setEmailError('Manager with this email is already added to the list');
      return false;
    }

    setLoading(true);
    let errors;
    try {
      errors = await validateEmail(email);
      setEmailError(errors);
    } catch (err) {
      // console.log(err);
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const isEmailValid = await handleEmailValidation();
    return isEmailValid;
  };

  const handleSubmitSuccess = () => {
    setExistingUserFormDisplay(false);
    confirmCb();
    Notification('success', t('Changes saved successfully'));
    return true;
  };

  const handleSubmitError = () => {
    setLoading(false);
    Notification('error', t('Your changes were not saved'), t('There was an error while saving your changes'));
    return false;
  };

  const updateExistingManager = (data) => {
    patchLicenseUser(managerToEditID, data)
      .then(res => handleSubmitSuccess(res))
      .catch(err => handleSubmitError(err, data, 'Cannot update license manager'));
  };

  const createNewManager = (data) => {
    addOrderManager(orderID, data)
      .then(res => handleSubmitSuccess(res))
      .catch(err => handleSubmitError(err, data, 'Cannot create license manager'));
  };

  const checkIfUserExisting = (data) => {
    checkExistingUser(email)
      .then((res) => {
        const isInUse = get(res, 'data.email_in_use');
        if (!isInUse) {
          createNewManager(data);
          return true;
        }

        setLoading(false);
        setExistingUserFormDisplay(true);
        return true;
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Error occured'), t('Your changes were not saved'));
      });
  };

  const addExistingUserAsManager = () => {
    if (isLoading) {
      return false;
    }

    setLoading(true);
    const data = { email };
    createNewManager(data);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);
    const data = {
      email,
      first_name: firstName || undefined,
      last_name: lastName || undefined,
      phone_number: phoneNumber || undefined,
      password: password || undefined,
    };

    if (!manager) {
      checkIfUserExisting(data);
    } else {
      updateExistingManager(data);
    }
    return true;
  };

  const handleClose = () => {
    if (!isDirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size="sm"
      title={manager ? t('Edit license manager') : t('Add license manager')}
    >
      <form className="OrderManagerForm" onSubmit={handleSubmit}>
        <div className="form-row">
          <Label text={t('Email')} inputId="email" />
          <TextInput
            id="email"
            disabled={!!manager}
            value={email}
            error={emailError}
            handleChange={handleEmailChange}
          />
        </div>
        <div className="form-row">
          <Label text={t('First Name')} inputId="firstname" />
          <TextInput
            id="firstname"
            value={firstName}
            handleChange={(val) => {
              setDirty(true);
              setFirstName(val);
            }}
          />
        </div>
        <div className="form-row">
          <Label text={t('Last Name')} inputId="lastname" />
          <TextInput
            id="lastname"
            value={lastName}
            handleChange={(val) => {
              setDirty(true);
              setLastName(val);
            }}
          />
        </div>
        <div className="form-row">
          <Label text={t('Phone number')} inputId="phone" />
          <TextInput
            id="phone"
            value={phoneNumber}
            handleChange={(val) => {
              setDirty(true);
              setPhoneNumber(val);
            }}
          />
        </div>
        {!manager && (
          <div className="form-row">
            <Label text={t('Password')} inputId="password" />
            <TextInput
              id="password"
              value={password}
              handleChange={(val) => {
                setDirty(true);
                setPassword(val);
              }}
            />
          </div>
        )}
      </form>
      {isExistingUserFormDisplayed && (
        <ExistingUserForm
          existingUser={email}
          closeCb={() => {
            setLoading(false);
            setExistingUserFormDisplay(false);
          }}
          confirmCb={addExistingUserAsManager}
          loading={isLoading}
        />
      )}
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

OrderManagerForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  orderID: PropTypes.number.isRequired,
  orderManagers: PropTypes.array,
  manager: PropTypes.object,
};

OrderManagerForm.defaultProps = {
  orderManagers: [],
  manager: null,
};

export default OrderManagerForm;

import api from 'shared/api';

export const generateLicense = (companyID, productShortCode, licenseQuantity) => {
  const url = `/api/v1/orders/generate_license/?product=${productShortCode}&quantity=${licenseQuantity}&company=${companyID}`;
  return api.get(url);
};

export const getOrder = (orderID) => {
  const url = `/api/v1/orders/${orderID}`;
  return api.get(url);
};

export const createOrder = data => api.post('/api/v1/orders/create_order', data);

export const getProductsInBundle = bundleID => api.get(`/api/v1/products/${bundleID}/bundle/`);

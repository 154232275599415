import React, { useState } from 'react';
import get from 'lodash.get';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ContentLoader,
  NotFound,
  Notification,
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import { useAPI } from 'shared/hooks';
import { isUserManager } from 'shared/helpers';
import LicenseDetails from './LicenseDetails';
import LicenseDevices from './LicenseDevices';
import LicenseUsers from './LicenseUsers';
import ProductFeatures from './ProductFeatures';
import BundleLicenseContainer from '../BundleLicenseContainer';
import CustomFields from './CustomFields';

const LicenseContainer = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const userDetails = useSelector(state => get(state, 'user.details'));
  const isManager = isUserManager(userDetails);
  const licenseID = get(match, 'params.licenseId');

  const [shouldGetLicenseAgain, setShouldGetLicenseAgain] = useState(0);

  const { isLoading: loading, value: license = null } = useAPI({
    url: `/api/v1/licenses/${licenseID}/`,
    onError: () => Notification('error', t('Error occured'), t('There was an error while getting license data')),
    dependenciesArray: [licenseID, shouldGetLicenseAgain],
  });

  const refetchLicense = () => setShouldGetLicenseAgain(shouldGetLicenseAgain + 1);

  if (loading) {
    return (
      <Page title="" breadcrumb={<NavLink to="/licenses">{t('Licenses')}</NavLink>}>
        <ContentLoader text={t('Getting license details')} />
      </Page>
    );
  }

  if (!license) {
    return <Page title="" breadcrumb={<NavLink to="/licenses">{t('Licenses')}</NavLink>}><NotFound /></Page>;
  }

  const displayLicenseTitle = () => {
    const licenseKey = get(license, 'license_key') || '';
    const licenseUsers = get(license, 'license_users') || [];
    const isFloating = get(license, 'is_floating') || get(license, 'is_floating_cloud');
    if (licenseKey && !licenseUsers.length) {
      return (
        <>
          {`${t('License')}: ${licenseKey}`}
          {isFloating && <span className="title-tag">{t('Floating')}</span>}
        </>
      );
    }
    return (
      <>
        {`${t('License for')}: ${get(license, 'product.product_name')}`}
        {isFloating && <span className="title-tag">{t('Floating')}</span>}
      </>
    );
  };

  // redundacy for edge cases when manager can also view licenses where he is only user
  const isLicenseManager = get(license, 'is_manager');

  const isUserAuth = get(license, 'product.authorization_method') === 'user';
  const product = get(license, 'product');
  const isBundleLicense = get(license, 'is_bundle');

  const isLicenseUser = !isManager || !isLicenseManager;
  const showUserTab = isUserAuth && !isLicenseUser;

  if (isBundleLicense) {
    return (
      <Page
        title={displayLicenseTitle()}
        breadcrumb={<NavLink to="/licenses">{t('Licenses')}</NavLink>}
      >
        <div className="LicenseContainer BundleLicenseContainer">
          <BundleLicenseContainer
            license={license}
            bundleProduct={product}
            refetchLicense={refetchLicense}
            isLicenseUser={isLicenseUser}
          />
        </div>
      </Page>
    );
  }

  return (
    <Page
      title={displayLicenseTitle()}
      breadcrumb={<NavLink to="/licenses">{t('Licenses')}</NavLink>}
    >
      <div className="LicenseContainer">
        <Tabs>
          <TabsHeader>
            <Tab>{t('License details')}</Tab>
            <Tab>{t('Product features')}</Tab>
            <Tab>{t('Custom fields')}</Tab>
            <Tab>{t('Devices')}</Tab>
            {showUserTab && <Tab>{t('License users')}</Tab>}
          </TabsHeader>
          <TabContent>
            <LicenseDetails
              license={license}
              getLicense={refetchLicense}
              isLicenseUser={isLicenseUser}
            />
          </TabContent>
          <TabContent>
            <ProductFeatures license={license} />
          </TabContent>
          <TabContent>
            <CustomFields license={license} product={product} />
          </TabContent>
          <TabContent>
            <LicenseDevices license={license} isLicenseUser={isLicenseUser} />
          </TabContent>
          {showUserTab && (
            <TabContent>
              <LicenseUsers
                license={license}
                getLicense={refetchLicense}
              />
            </TabContent>
          )}
        </Tabs>
      </div>
    </Page>
  );
};

export default LicenseContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { getUserFromStorage } from 'keycloak/authUser';
import UnauthorizedNotice from '../UnauthorizedNotice';

const IsAuthorized = ({ children }) => {
  const user = getUserFromStorage();

  if (
    !user
  ) {
    return <UnauthorizedNotice />;
  }

  return children;
};

IsAuthorized.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  userRole: PropTypes.string,
};

IsAuthorized.defaultProps = {
  userRole: 'user',
};

export default IsAuthorized;

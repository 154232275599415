/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DescriptionTable,
  StatusCard,
  Notification,
  TooltipHeader,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  getLicenseStatus,
  displayMaxOveragesValue,
  capitalizeFirstLetter,
  parseObject,
  getPortalData,
  hasPermission,
  isUserManager,
} from 'shared/helpers';
import {
  defaultDateFormat,
  initialPortalData,
  licenseTypes,
} from 'shared/constants';
import { ResetLicenseForm, ProductDownloadTable } from 'licenses/components';

const LicenseDetails = ({
  getLicense,
  license,
  isLicenseUser,
  isBundleItem,
}) => {
  const { t } = useTranslation();
  const userDetails = useSelector(state => get(state, 'user.details'));
  const isManager = isUserManager(userDetails);

  const userCompany = useSelector(state => get(state, 'user.details.company'));
  const permissionsList = useSelector(state => get(state, 'user.details.permissions'));
  const { portal_data } = userCompany;
  const portalData = parseObject(portal_data, initialPortalData);
  const showProductLinks = get(portalData, 'showProductLinks') || false;
  const allowUsersToResetLicense = getPortalData(portalData, 'allowUsersLicenseReset', initialPortalData);

  const [isResetFormDisplayed, setResetFormDisplay] = useState(false);

  const status = getLicenseStatus(license.enabled, license.active);
  const licenseStatus = status.positive ? 'success' : 'error';
  const licenseStatusText = status.message;
  const isAuthUserBased = get(license, 'product.authorization_method') === 'user';
  const installationFiles = get(license, 'product.latest_installation_files') || {};
  const hasInstallationFiles = Object.keys(installationFiles).length !== 0;
  const canBorrow = get(license, 'can_borrow');

  const shouldHideReset = isLicenseUser && !allowUsersToResetLicense;

  const handleLicenseReset = () => {
    const permission = hasPermission(permissionsList, 'reset_license');
    if (!permission && !allowUsersToResetLicense) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
    } else {
      setResetFormDisplay(!isResetFormDisplayed);
    }
  };

  const licenseStartDate = get(license, 'start_date');
  const isEnabled = get(license, 'enabled');
  const isTrial = get(license, 'is_trial');
  const isConsumption = get(license, 'license_type') === licenseTypes.consumption;
  const isSubscription = get(license, 'license_type') === licenseTypes.subscription;
  const allowGracePeriod = get(license, 'allow_grace_period');
  const gracePeriod = get(license, 'grace_period');
  const isFloatingLicense = get(license, 'is_floating') || get(license, 'is_floating_cloud');
  const floatingTimeout = get(license, 'floating_timeout') || get(license, 'product.floating_timeout');

  return (
    <div className="LicenseDetails">
      <div className="list-header">
        {!shouldHideReset && !isBundleItem && (
          <div>
            <Button
              theme="error"
              size="sm"
              onClick={handleLicenseReset}
            >
              {t('Reset license')}
            </Button>
          </div>
        )}
        <div />
      </div>
      <DescriptionTable
        details={[
          {
            label: t('Order'),
            value: isManager ? <NavLink to={`/orders/${get(license, 'order')}`}>{get(license, 'order_store_id')}</NavLink> : displayValue(get(license, 'order_store_id')),
          },
          { label: t('Product'), value: displayValue(get(license, 'product.product_name')) },
          { label: t('Type'), value: displayValue(capitalizeFirstLetter(t(get(license, 'license_type')))) },
          {
            label: licenseStartDate ? TooltipHeader(t('Start Date'), t('License cannot be activated before this date')) : null,
            value: formatDate(licenseStartDate, defaultDateFormat),
          },
          {
            label: isEnabled ? TooltipHeader(t('Expiration Date')) : null,
            value: formatDate(get(license, 'validity_period'), defaultDateFormat),
          },
          {
            label: !isTrial ? t('Valid duration') : null,
            value: displayValue(get(license, 'valid_duration')),
          },
          {
            label: (isSubscription && allowGracePeriod) ? t('Grace period') : null,
            value: `${gracePeriod} ${t('hours')}`,
          },
          { label: t('Status'), value: <StatusCard text={t(licenseStatusText)} status={licenseStatus} /> },
          { label: t('Created on'), value: formatDate(get(license, 'created_at')) },
          { label: t('Activated on'), value: formatDate(get(license, 'time_activated')) },
          { label: t('Last checked'), value: formatDate(get(license, 'last_check')) },
          { label: t('Last updated'), value: formatDate(get(license, 'updated_at')) },
          {
            label: isEnabled ? null : t('Disabled on'),
            value: formatDate(get(license, 'time_disabled')),
          },
          {
            label: t('Max activations'),
            value: get(license, 'allow_unlimited_activations') ? t('Unlimited') : displayValue(get(license, 'max_activations')),
          },
          { label: t('Times activated'), value: displayValue(get(license, 'times_activated'), '0') },
          { label: t('Transfer count'), value: displayValue(get(license, 'transfer_count'), '0') },
          {
            label: get(license, 'max_transfers') ? t('Device transfer limit') : null,
            value: displayValue(get(license, 'max_transfers')),
          },
          {
            label: t('Is trial'),
            value: get(license, 'is_trial') ? t('Yes') : t('No'),
          },
          {
            label: (get(license, 'is_trial') && get(license, 'trial_days')) ? t('Trial days') : null,
            value: displayValue(get(license, 'trial_days')),
          },
          {
            label: isAuthUserBased && (get(license, 'max_license_users') || get(license, 'max_license_users') === 0) ? t('Max license users') : null,
            value: get(license, 'max_license_users') === 0 ? t('Unlimited') : displayValue(get(license, 'max_license_users')),
          },
          // consumption license type data
          {
            label: isConsumption ? t('Max consumptions') : null,
            value: get(license, 'allow_unlimited_consumptions') ? t('Unlimited') : displayValue(get(license, 'max_consumptions'), '0'),
          },
          {
            label: isConsumption ? t('Allow negative consumptions') : null,
            value: get(license, 'allow_negative_consumptions') ? t('Yes') : t('No'),
          },
          {
            label: isConsumption ? t('Total consumptions') : null,
            value: displayValue(get(license, 'total_consumptions'), '0'),
          },
          {
            label: isConsumption ? t('Allow overages') : null,
            value: get(license, 'allow_overages') ? t('Yes') : t('No'),
          },
          {
            label: isConsumption ? t('Max overages') : null,
            value: displayMaxOveragesValue(license),
          },
          {
            label: isConsumption ? t('Reset consumption') : null,
            value: get(license, 'reset_consumption') ? t('Yes') : t('No'),
          },
          {
            label: isConsumption ? t('Consumption period') : null,
            value: displayValue(capitalizeFirstLetter(get(license, 'consumption_period'))),
          },
          // floating licenses data
          {
            label: get(license, 'is_floating') ? t('Offline floating license') : null,
            value: get(license, 'is_floating') ? t('Yes') : t('No'),
          },
          {
            label: get(license, 'is_floating_cloud') ? t('Is floating cloud') : null,
            value: get(license, 'is_floating_cloud') ? t('Yes') : t('No'),
          },
          {
            label: isFloatingLicense ? t('Max simultaneous license users') : null,
            value: displayValue(get(license, 'floating_users')),
          },
          {
            label: isFloatingLicense ? t('Floating timeout') : null,
            value: `${displayValue(floatingTimeout)} min`,
          },
          {
            label: get(license, 'is_floating_cloud') ? t('Floating in use') : null,
            value: `${get(license, 'floating_in_use_devices') || '0'} / ${get(license, 'floating_users')}`,
          },
          {
            label: canBorrow ? t('Can borrow') : null,
            value: canBorrow ? t('Yes') : t('No'),
          },
          {
            label: canBorrow ? t('Max borrow time') : null,
            value: `${displayValue(get(license, 'max_borrow_time'))} ${t('hours')}`,
          },
          // show everything else
          {
            label: get(license, 'enable_maintenance_period') ? t('Maintenance duration') : null,
            value: displayValue(get(license, 'maintenance_duration')),
          },
          {
            label: get(license, 'maintenance_period') ? t('Maintenance End Date') : null,
            value: formatDate(get(license, 'maintenance_period'), defaultDateFormat),
          },
          { label: t('Prevent virtual machine'), value: get(license, 'prevent_vm') ? t('Yes') : t('No') },
          { label: t('Note'), value: displayValue(get(license, 'note')) },
        ]}
      />
      {showProductLinks && hasInstallationFiles && (
        <div className="product-links">
          <ProductDownloadTable files={installationFiles} />
        </div>
      )}
      {
        isResetFormDisplayed && (
          <ResetLicenseForm
            close={() => setResetFormDisplay(false)}
            confirmCb={() => setResetFormDisplay(false)}
            license={license}
            getLicense={getLicense}
          />
        )
      }
    </div>
  );
};

LicenseDetails.propTypes = {
  getLicense: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
  isLicenseUser: PropTypes.bool,
  isBundleItem: PropTypes.bool,
};

LicenseDetails.defaultProps = {
  isLicenseUser: false,
  isBundleItem: false,
};

export default LicenseDetails;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash.get';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  ContentLoader,
  CustomPrompt,
  IsAuthorized,
} from 'shared/components';
import DomainCheck from 'app/DomainCheck';
import { parseObject } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import LsLogo from 'assets/ls_logo.png';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const customDomainData = useSelector(state => get(state, 'user.customDomain'));

  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, hasTriedSignin]);

  useEffect(() => auth.events.addAccessTokenExpiring(() => {
    auth.signinRedirect();
  }),
  [auth.events, auth.signinRedirect]);

  if (auth.isLoading || !auth.user) {
    return <ContentLoader text="" />;
  }

  const portalData = parseObject(get(customDomainData, 'portal_data'), initialPortalData);
  const name = get(customDomainData, 'name') || 'LicenseSpring';
  const customTitle = get(portalData, 'loginTitle') || `${name} ${t('User Portal')}`;
  const favicon = get(customDomainData, 'logo') || get(customDomainData, 'favicon') || LsLogo;

  return (
    <div className="App">
      <Helmet>
        <title>{customTitle}</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <BrowserRouter
        getUserConfirmation={(message, callback) => (
          CustomPrompt(message, callback, t('Warning'))
        )}
      >
        <Switch>
          <Route
            path="/"
            render={() => (
              <IsAuthorized>
                <DomainCheck />
              </IsAuthorized>
            )}
          />
        </Switch>
      </BrowserRouter>
      <ToastContainer
        autoClose={3000}
        closeButton={false}
        toastClassName="Notification-container"
      />
    </div>
  );
};

export default App;

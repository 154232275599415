import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { getUserFromStorage, generateUpdatePasswordUrl } from 'keycloak/authUser';
import {
  Button,
  DescriptionTable,
  Page,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import EditProfileForm from '../EditProfileForm';

const ProfileDetails = () => {
  const user = getUserFromStorage();
  const { t } = useTranslation();
  const [isEditFormDisplayed, setEditFormDisplay] = useState(false);

  const handlePasswordChange = async () => {
    const url = await generateUpdatePasswordUrl();
    window.location.replace(url);
  };

  return (
    <div className="ProfileDetails">
      <Page
        title={t('My profile')}
        breadcrumb={<NavLink to="/">{t('Home')}</NavLink>}
      >
        <div className="ProfileDetails-inner">
          <div className="list-header">
            <div>
              <Button
                theme="success"
                onClick={() => setEditFormDisplay(true)}
              >
                {t('Edit profile')}
              </Button>
            </div>
            <div>
              <Button
                theme="info"
                onClick={handlePasswordChange}
              >
                {t('Change password')}
              </Button>
            </div>
          </div>
          <DescriptionTable
            details={[
              { label: t('Email'), value: displayValue(get(user, 'profile.email')) },
              { label: t('Name'), value: displayValue(get(user, 'profile.name')) },
              { label: t('Phone number'), value: displayValue(get(user, 'profile.phone_number')) },
            ]}
          />
        </div>
      </Page>
      {isEditFormDisplayed && (
      <EditProfileForm
        user={user}
        close={() => setEditFormDisplay(false)}
      />
      )}
    </div>
  );
};

export default ProfileDetails;

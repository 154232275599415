import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'shared/helpers';
import { defaultDateFormat } from 'shared/constants';
import './styles.scss';

const ProductDownloadItem = ({ file }) => {
  const { t } = useTranslation();
  return (
    <div className="ProductDownloadItem">
      <div className="row">
        <span className="label">{`${t('File name')}:`}</span>
        <span className="value">{get(file, 'filename')}</span>
      </div>
      <div className="row">
        <span className="label">{`${t('Version')}:`}</span>
        <span className="value">{get(file, 'version')}</span>
      </div>
      <div className="row">
        <span className="label">{`${t('Release date')}:`}</span>
        <span className="value">{formatDate(get(file, 'release_date'), defaultDateFormat)}</span>
      </div>
      {get(file, 'release_notes_link') && (
        <div className="row">
          <span className="label">{`${t('Release notes')}:`}</span>
          <span className="value">
            <a href={get(file, 'release_notes_link')} target="_blank" rel="noopener noreferrer">
              {get(file, 'release_notes_link')}
            </a>
          </span>
        </div>
      )}
      {get(file, 'eula_link') && (
        <div className="row">
          <span className="label">{`${t('EULA')}:`}</span>
          <span className="value">
            <a href={get(file, 'eula_link')} target="_blank" rel="noopener noreferrer">
              {get(file, 'eula_link')}
            </a>
          </span>
        </div>
      )}
      {get(file, 'full_link') && (
        <a className="download-link" href={get(file, 'full_link')} target="_blank" rel="noopener noreferrer">{t('Download')}</a>
      )}
    </div>
  );
};

ProductDownloadItem.propTypes = {
  file: PropTypes.object.isRequired,
};

export default ProductDownloadItem;

import api from 'shared/api';

export const addLicenseUser = (licenseId, data) => {
  const url = `/api/v1/licenses/${licenseId}/assign_user/`;
  return api.post(url, data);
};

export const checkExistingUser = (userEmail) => {
  const email = encodeURIComponent(userEmail);
  const url = `/api/v1/users/check_user_email?email=${email}`;
  return api.get(url);
};

import React, { useState, useEffect, useReducer } from 'react';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  ContentLoader,
  FormStepper,
  FormStepperItem,
  Forbidden,
  Notification,
  Page,
} from 'shared/components';
import { hasPermission } from 'shared/helpers';
import { getManagerCounter } from 'redux/user/actions';
import { getOrder, createOrder } from './actions';
import { initialState } from './constants';
import {
  newOrderReducer,
  prefillExistingOrderDetails,
  setCustomerDetails,
  setSelectedProducts,
} from './helpers';
import {
  CustomerStep,
  ProductsStep,
  VerifyOrderStep,
} from './components';
import './styles.scss';

const CreateOrderForm = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const userID = useSelector(state => get(state, 'user.details.id'));
  const companyID = useSelector(state => get(state, 'user.details.company.id'));
  const permissionsList = useSelector(state => get(state, 'user.details.permissions'));
  const canCreateOrder = hasPermission(permissionsList, 'create_order');
  const canIssueLicenses = hasPermission(permissionsList, 'issue_license');

  const [formState, dispatch] = useReducer(newOrderReducer, initialState);
  const [orderFetch, setOrderFetch] = useState(false);
  const [orderCreating, setOrderCreating] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [formStep, setFormStep] = useState(1);

  const handleEmptyOrderId = () => {
    dispatch(prefillExistingOrderDetails({
      id: '',
      customerReference: '',
      customerFirstName: '',
      customerLastName: '',
      customerEmail: '',
      customerOrganization: '',
      customerAddress: '',
      customerCity: '',
      customerState: '',
      customerCountry: '',
      customerZipcode: '',
      customerPhoneNumber: '',
    }));
  };

  const fetchOrderDetails = async () => {
    setOrderFetch(true);
    let order;

    try {
      order = await getOrder(orderId);
    } catch (err) {
      setOrderFetch(false);
    }

    const orderData = get(order, 'data');

    if (orderData) {
      const orderCustomer = get(orderData, 'customer');
      const data = {
        id: get(orderData, 'store_id'),
        orderReference: get(orderData, 'customer_reference'),
        customerReference: get(orderCustomer, 'reference') || '',
        customerFirstName: get(orderCustomer, 'first_name') || '',
        customerLastName: get(orderCustomer, 'last_name') || '',
        customerEmail: get(orderCustomer, 'email') || '',
        customerOrganization: get(orderCustomer, 'company_name') || '',
        customerAddress: get(orderCustomer, 'address') || '',
        customerCity: get(orderCustomer, 'city') || '',
        customerState: get(orderCustomer, 'state') || '',
        customerCountry: get(orderCustomer, 'country') || '',
        customerZipcode: get(orderCustomer, 'postcode') || '',
        customerPhoneNumber: get(orderCustomer, 'phone') || '',
      };

      dispatch(prefillExistingOrderDetails(data));
      setOrderFetch(false);
      setDirty(true);
    }
  };

  const handleBack = () => setFormStep(formStep - 1);
  const handleForward = () => setFormStep(formStep + 1);

  const handleBackAndSaveProducts = (data) => {
    dispatch(setSelectedProducts(data));
    setFormStep(formStep - 1);
  };

  const handleCustomerDetailsSubmit = (data) => {
    dispatch(setCustomerDetails(data));
    setFormStep(2);
  };

  const handleSelectedProductsSubmit = (data) => {
    dispatch(setSelectedProducts(data));
    setFormStep(3);
  };

  const handleOrderCreate = (data) => {
    setOrderCreating(true);
    setDirty(false);
    createOrder(data)
      .then((res) => {
        dispatchRedux(getManagerCounter(userID, companyID));
        Notification('success', t('Order created'), `${t('Order ID')}: ${get(res, 'data.store_id')}`);
        history.push(`/orders/${get(res, 'data.id')}/`);
      })
      .catch(() => {
        setOrderCreating(false);
        setDirty(true);
        setOrderCreating(false);
        Notification('error', t('Error occured'));
      });
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails();
    } else {
      handleEmptyOrderId();
    }
  }, [orderId]);

  const isPageAvailable = () => {
    if (orderId) {
      return canIssueLicenses;
    }
    return canCreateOrder;
  };

  if (!isPageAvailable()) {
    return <Page><Forbidden text={t('Missing permission')} description={t('You do not have permission to perform this action.')} /></Page>;
  }

  if (orderFetch) {
    return <Page><ContentLoader text={t('Getting order details')} /></Page>;
  }

  return (
    <Page title={orderId ? t('Issue Licenses') : t('Create order')}>
      <Prompt
        message={t('By leaving this form you will lose all progress. Are you sure you want to leave?')}
        when={isDirty}
      />
      <div className="CreateOrderForm">
        <FormStepper>
          <FormStepperItem
            title={t('Customer')}
            description={t('Enter customer information')}
            isActive={formStep === 1}
            isCompleted={formStep > 1}
            step={1}
          />
          <FormStepperItem
            title={t('Products')}
            description={t('Add products to order')}
            isActive={formStep === 2}
            isCompleted={formStep > 2}
            step={2}
          />
          <FormStepperItem
            title={t('Confirm order')}
            description={t('Verify order details')}
            isActive={formStep === 3}
            isCompleted={formStep > 3}
            step={3}
          />
        </FormStepper>
        {formStep === 1 && (
          <CustomerStep
            companyID={companyID}
            formState={formState}
            handleForward={handleForward}
            handleSubmit={handleCustomerDetailsSubmit}
            orderID={orderId}
            setDirty={setDirty}
          />
        )}
        {formStep === 2 && (
          <ProductsStep
            companyID={companyID}
            formState={formState}
            handleBack={handleBackAndSaveProducts}
            handleSubmit={handleSelectedProductsSubmit}
          />
        )}
        {formStep === 3 && (
          <VerifyOrderStep
            companyID={companyID}
            formState={formState}
            handleBack={handleBack}
            handleOrderCreate={handleOrderCreate}
            loading={orderCreating}
            orderID={orderId}
          />
        )}
      </div>
    </Page>
  );
};

export default CreateOrderForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { displayValue } from 'shared/helpers';
import {
  DescriptionTable,
  ConfirmationPopup,
  Notification,
} from 'shared/components';
import {
  addLicenseUser,
} from '../actions';
import './styles.scss';

const ExistingUserForm = ({
  closeCb,
  confirmCb,
  existingUser,
  licenseId,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      email: existingUser,
    };

    addLicenseUser(licenseId, data)
      .then(() => {
        Notification(
          'success',
          t('Changes saved successfully'),
          t('License user has been assigned'),
        );
        confirmCb();
      })
      .catch(() => {
        setLoading(false);
        Notification(
          'error',
          t('Your changes were not saved'),
          t('There was an error while saving your changes'),
        );
      });
  };

  return (
    <ConfirmationPopup
      confirmCb={handleSubmit}
      closeCb={closeCb}
      disabled={loading}
      title={t('User with this email already exist')}
      warning
    >
      <div className="ExistingUserForm">
        <div className="ExistingUserForm-message">
          {t('Assign this user to the license?')}
        </div>
        <DescriptionTable
          details={[
            {
              label: t('Email'),
              value: displayValue(existingUser),
            },
          ]}
        />
      </div>
    </ConfirmationPopup>
  );
};

ExistingUserForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  existingUser: PropTypes.object.isRequired,
  licenseId: PropTypes.number.isRequired,
};

ExistingUserForm.defaultProps = {
};

export default ExistingUserForm;
